.common-poster {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
  width: 410px;
  height: calc(100vh - 32px - 32px);
  padding: 32px 32px 20px;
  overflow: hidden;
  background: #f2f4f6;
  border-radius: 20px;
}

.common-layout-header {
  flex-shrink: 0;
}

.common-layout-image {
  flex: 1 1 auto;
  width: 100%;
  min-height: 100px;
  max-height: 240px;
}

.common-layout-image .ant-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.common-layout-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.common-layout-footer {
  display: flex;
  flex-flow: wrap;
  flex-shrink: 0;
  gap: 0 16px;
}

@media (max-width: 991px) {
  .common-poster {
    width: 100vw;
    height: auto;
    padding: 20px;
    background: #f2f3f7;
    border-radius: 0;
  }

  .common-layout-footer {
    display: none;
  }

  .common-layout-image {
    display: none;
  }
}
