.secondary-link a.condo-typography {
  color: #707695;
  text-decoration-color: #707695;
}

.secondary-link a.condo-typography:hover,
.secondary-link a.condo-typography:focus {
  color: #2bc359;
  text-decoration-color: #2bc359;
}
